
.intro {
  background: $dgreen;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 800px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.88;
  text-align: center;

  .intro-background {
    background-image: url('./../images/checkingspreadsheet.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    opacity: .2;
    position: absolute;
    left: 0;
    top: 0;
  }

  .intro-group {
    text-align: center;
    position: relative;
    max-width: 600px;
    z-index: 1;
    color: $offwhite;

    @include respond-to(phone) {
      max-width: 300px;
    }
    @include respond-to(landscape) {
      min-width: 300px;
    }

    .intro-logo {
      font-size: $fontsize * 2;
      margin: 0;
      position: relative;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      line-height: .85;

      @include respond-to(phone) {
        font-size: $fontsize * 1.5;
      }
      
      span {
        text-transform: uppercase;
      }
      
      .logo-big-parts {
        font-size: $fontsize * 5;

        @include respond-to(phone) {
          font-size: $fontsize * 3.5;
        }
        @include respond-to(landscape) {
          font-size: $fontsize * 4;
        }
      }
    }

    p {
      font-size: $fontsize;
      width: 95%;
      margin: auto;
    }

    .scroll {
      position: relative;
      bottom: -100px;

      @include respond-to(phone) {
        bottom: -50px;
      }

      i {
        font-size: 50px;
        color: white;
        transition: all .2s;

        &:hover {
          color: $limegreen;
        }
      }
    }
  }

} // -------- INTRO ---------
