
.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  color: $offwhite;
  transition: all .4s;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    text-transform: uppercase;  
    width: fit-content;  

    @include respond-to(landscape) {
      width: 100%;
    }
    @include respond-to(phone) {
      width: 100%;
    }
    

    a {
      text-decoration: none;
      font-size: $fontsize * 1.5;
      color: $offwhite;
      transition: all .2s;
      width: 100%;

      @include respond-to(tablet) {
        font-size: $fontsize * 1.4;
      }

      &:hover {
        color: $limegreen !important;
      }
    }
  }

  .right-nav {
    width: 50%;
    display: flex;
    align-items: center;

    @include respond-to(tablet) {
      width: 60%;
    }

    .burger {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 2rem;
      height: 2rem;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 10;
      position: absolute;
      right: 50px;

      &:focus {
        outline: none;
      }

      div {
        width: 2rem;
        height: .25rem;
        border-radius: 10px;
        transition: all .3s linear;
        background: $offwhite;
        transform-origin: 1px;
      }
    }

    .nav-links {
      width: 100%;
      list-style: none;
      display: flex;
      justify-content: space-evenly;
      padding: 0;


      li {
        font-size: $fontsize;
        height: 100%;

        @include respond-to(phone) {
          font-size: 10px;
        }
        
        a {
          text-decoration: none;
          text-transform: uppercase;
          transition: all .4s;
          width: 100%;
          height: 100%;
          padding-bottom: 5px;

          &:hover {
            color: $limegreen !important;
            border-bottom: 1px solid $limegreen;
          }
        }
      }
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $offwhite;
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    right: 0;


    a {
      color: #444;
      text-decoration: none;
      text-transform: uppercase;
      font-size: $fontsize;
      font-weight: bold;
      margin: 2rem 0;
      transition: all .4s linear;

      &:hover {
        text-decoration: underline;
        color: $greentext;
      }
    }
  }
}