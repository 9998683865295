.footer {
  background: $dgreen;
  padding: 0;
  color: $offwhite;
  width: 100%;
  // position: absolute;
  bottom: 0;
  left: 0;

  .footer-contact {
    padding: 80px 0;
    background: $ldgreen;
    color: $offwhite;

    @include respond-to(landscape) {
      padding: 40px 0;
    }
    @include respond-to(phone) {
      padding: 40px 0;
    }

    .wrapper {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;

      @include respond-to(landscape) {
        flex-direction: column;
      }
      @include respond-to(phone) {
        flex-direction: column;
      }

      .leftside {
        h3 {
          font-size: $fontsize;
          margin: 0;
          border-bottom: 2px solid $offwhite;
          text-transform: uppercase;
          letter-spacing: 2px;
          padding-bottom: 10px;
        }

        h2 {
          margin: 0;
          font-size: $fontsize * 3;
          font-weight: 400;

          @include respond-to(landscape) {
            font-size: $fontsize * 2;
          }
          @include respond-to(phone) {
            font-size: $fontsize * 2;
          }
        }
      }

      .rightside {
        display: flex;

        @include respond-to(landscape) {
          justify-content: flex-start;
          margin-top: 20px;
        }
        @include respond-to(phone) {
          justify-content: flex-start;
          margin-top: 20px;
        }

        a {
          color: $ldgreen;
          background: $palegreen;
          text-decoration: none;
          text-transform: uppercase;
          font-size: $fontsize;
          padding: 20px;
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          transition: all 0.4s;

          @include respond-to(tablet) {
            width: 200px;
          }

          @include respond-to(landscape) {
            width: 100%;
          }

          &:hover {
            background: $offwhite;
          }
        }
      }
    }
  }

  .footer-wrapper {
    width: 80%;
    margin: auto;

    .info {
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 80px 0;

      @include respond-to(phone) {
        display: block;
        padding: 40px 0;
      }

      p {
        margin: 0;

        @include respond-to(landscape) {
          font-size: $fontsize * 0.8;
        }
      }

      .contact {
        @include respond-to(phone) {
          margin-bottom: 20px;
        }
      }

      .logo-footer {
        font-size: $fontsize * 2;
        line-height: 1;
        color: $limegreen;
        margin-bottom: 10px;

        @include respond-to(landscape) {
          font-size: $fontsize * 1.3;
        }
        @include respond-to(phone) {
          font-size: $fontsize * 1.3;
          margin-bottom: 5px;
        }
      }

      a {
        margin-right: 10px;
        color: $limegreen;
        text-decoration: none;
        display: block;
        transition: all 0.4s;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
          color: $offwhite;
        }
      }
    }
  }
}
