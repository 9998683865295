@import './variables.scss';

@mixin respond-to($media) {
  @if $media == phone {
    @media only screen and (max-width: 380px) { @content; }
  }
  @else if $media == landscape {
    @media only screen and (min-width: 381px) and (max-width: 768px) { @content; }
  }
  @else if $media == tablet {
    @media only screen and (min-width: 769px) and (max-width: 1023px) { @content; }
  }
  @else if $media == laptop {
    @media only screen and (min-width: 1024px) { @content; }
  }
}


@import './navbar.scss';
@import './about.scss';
@import './footer.scss';
@import './about.scss';
@import './contact.scss';
@import './intro.scss';
@import './resources.scss';
@import './services.scss';
@import './team.scss';



body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overscroll-behavior-y: none;
  font-family: 'Roboto',Arial, Helvetica, sans-serif;
  background-color: $offwhite;
}

#root {
  min-height: 100vh;

}

.wrapper {
  overflow: hidden;
  position: relative;
}


.main {
  min-height: 100%;
  position: relative;
  // padding-bottom: 575px;
}


.covid {
  height: 100vh;
  padding-top: 60px;

  .wrapper {
    width: 80%;
    margin: auto;

    .header {
      margin-top: 80px;
      text-align: center;

      h2 {
        font-size: $fontsize * 2;

      }
    }
  }
}
