
.services {
  width: 80%;
  margin: 0 auto;
  padding: 80px 0;
  height: fit-content;
  color: $dgray;

  @include respond-to(phone) {
    padding: 40px 0;
  }
  @include respond-to(landscape) {
    padding: 40px 0;
  }


  .services-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    @include respond-to(landscape) {
      margin-bottom: 20px;
    }
    @include respond-to(phone) {
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 405px) {
      align-items: flex-start;
    }

    h3 {
      font-size: $fontsize * 3;
      margin: 0 auto;

      @include respond-to(phone) {
        font-size: $fontsize * 2;
      }
      @include respond-to(landscape) {
        font-size: $fontsize * 2;
      }
    }

    p {
      font: $fontsize;
      // text-align: center;
    }
  }

  .services-body {

    .service-cards {
      list-style: none;
      justify-content: space-evenly;
      margin: 0;
      padding: 0;
      width: 100%;
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 20px;

      @include respond-to(phone) {
        grid-template-columns: 1fr;
      }

      @include respond-to(landscape) {
        grid-template-columns: 1fr 1fr;
      }

      .card {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 5px;
        border: 5px solid transparent;
        transition: all .5s;

        @include respond-to(landscape) {
          // min-width: 50px;
          margin-right: 0;
          width: initial;
        }

        @include respond-to(tablet) {
          padding: 0;
          padding-top: 10px;
        }

        i {
          font-size: $fontsize * 3;
          color: $greentext;

          @include respond-to(tablet) {
            font-size: $fontsize * 2.5;
          }
        }

        h3 {
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 2px;

          @include respond-to(tablet) {
            font-size: $fontsize;
          }
        }

      }
    }

    .cardDetails {
    
      p {
        font-size: $fontsize * 1.8;
        font-weight: 300;
        margin: 40px 0 0 0;

        @include respond-to(landscape) {
          font-size: $fontsize * 1.5;
        }
      }
    }
  }
}