
.contact_us {
  padding: 60px 0;
  height: 70vh;
  width: 80%;
  margin: auto;
  color: $dgray;

  h2 {
    font-size: $fontsize * 2;
    margin: 80px 0 0 0;
  }

  p {
    margin-bottom: 40px;
  }

  h2, p {
    text-align: center;
  }

  form {
    color: $dgray;
    width: 60%;
    margin: auto;

    @include respond-to(phone) {
      width: 100%;
    }


    .form-group {
      text-align: center;
      margin-bottom: 20px;
      width: 100%;

      .form-control {
        width: 100%;
        height: 2.5rem;
        font-size: $fontsize;
        padding: 0 20px;
        color: $dgray;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
        box-sizing: border-box;

        @include respond-to(phone) {
          padding: 0 0 0 20px;
          width: 100%;
        }


        &:focus {
          background-color: $palegreen;
          outline: none;
          border-color: $palegreen;
        }
      }

      textarea {
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
        font-size: $fontsize;
        padding: 12px 20px;
        border: 2px solid #ccc;
        border-radius: 4px;
        background-color: #f8f8f8;
        resize: none;
        box-sizing: border-box;

        &:focus {
          background-color: $palegreen;
          outline: none;
          border-color: $palegreen;

        }
      }
    }

    .contact-form-btn {
      width: 200px;
      height: 50px;
      background: $greentext;
      font-size: $fontsize;
      color: white;
      border: none;
      border-radius: 2%;
      transition: all .4s;

      @include respond-to(phone) {
        width: 100%;
      }

      &:hover {
        cursor: pointer;
        background: none;
        border: 2px solid $greentext;
        color: #444;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.sent-confirmation {
  text-align: center;
  padding: 20px;
  font-size: $fontsize * 2;
  width: 500px;
  margin:auto;
  background: $greentext;
  color: white;

  @include respond-to(phone) {
    width: initial;
    font-size: $fontsize * 1.5;
  }
  @include respond-to(landscape) {
    width: initial;
  }

  p {
    margin: 0 auto 40px;
  }

  img {
    width: 100%;
  }
}
