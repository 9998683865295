.about-container {
  background: $lgray;
  padding: 80px 0;


  .about {
    width: 80%;
    color: $dgray;
    margin: 0 auto;

    h2 {
      margin: 0;
      margin-bottom: 60px;
      font-size: $fontsize * 3;
      font-weight: 200;
      max-width: 1000px;
      color: #444;

      span {
        color: $dgreen;
        font-style: oblique;
      }
    }

    .header-img {
      // display: block;
      // margin: 60px auto;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .aboutus {
      p {
        margin: 20px 0;

        span {
          color: $ldgreen;
        }
      }
    }
  }
}
