$fontsize: 20px;

$greentext: #7FB069;
$dgreen: #1d6341;
$ldgreen: #247f53;
$darkblue: #004346;
$limegreen: #BCE784;
$palegreen: #C7D59F;
$lgray: #edebeb;

$dgray: #444;
$offwhite: #fdfdfd;