
.team {
  background: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  // padding: 80px 0;
  padding-top: 60px;

  margin-bottom: 20px;

  @include respond-to(phone) {
    // padding: 40px 0;
  }
  @include respond-to(landscape) {
    // padding: 40px 0;
  }

  .team-head {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    @media only screen and (max-width: 405px) {
      align-items: flex-start;
    }

    p {
      margin: 20px 0;

      @include respond-to(landscape) {
        margin: 10px 0;
      }
      @include respond-to(phone) {
        margin: 10px 0;
      }
    }
  }

  h2 {
    color: $dgray;
    font-size: $fontsize * 2;
    padding-bottom: 0;
    margin: 0;

    @include respond-to(phone) {
      font-size: $fontsize * 2;
    }

    @include respond-to(landscape) {
      font-size: $fontsize * 2;
    }
  }

  p {
    font-size: $fontsize;
  }

  .member-list {
    width: 80%;
    height: 100%;
    padding: 20px;
    color: $dgray;

  
    @include respond-to(phone) {
      width: initial;
    }


    .team-member {
      width: 100%;
      // border: 5px solid $dgreen;
      display: flex;
      align-items: center;
      // background: $offwhite;
      border-radius: 5px;
      // box-sizing: border-box;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      &:nth-of-type(odd) .info {
        padding-left: 20px;
        // margin-left: 0;
      }
      &:nth-of-type(even) .info {
        padding-right: 20px;
        // margin-left: 0;
      }

      @include respond-to(tablet) {
        flex-direction: column;
        padding: 20px;
        width: initial;

        &:nth-child(even) {
          flex-direction: column;
        }
      }
      @include respond-to(landscape) {
        flex-direction: column;
        padding: 20px;
        width: initial;

        &:nth-child(even) {
          flex-direction: column;
        }
      }
      @include respond-to(phone) {
        flex-direction: column;
        padding: 20px;
        width: initial;

        &:nth-child(even) {
          flex-direction: column;
        }

      }

      .photo {
        width: 50%;
        height: 100%;
        // height: 275px;
        // margin: 20px 0;

        @include respond-to(tablet) {
          width: 100%;
          margin: 0;
        }

        @include respond-to(landscape) {
          margin: 0;
          min-width: 200px;
          width: 100%;
          margin: 0;
        }
        @include respond-to(phone) {
          margin: 0;
          min-width: 150px;
          width: 100%;
          margin: 0;
        }
        
        img {
          width: 100%;
          // height: 100%;
          // max-height: auto;
        }
      }

      .info {
        // width: 60%;
        width: 50%;
        // margin: 20px 20px 20px 0;
        // margin-right: 20px;
        box-sizing: border-box;

        @include respond-to(tablet) {
          width: 100%;
          margin: 0;
          margin-top: 20px;
        }
        @include respond-to(landscape) {
          width: 100%;
          margin: 0;
          margin-top: 20px;
        }
        @include respond-to(phone) {
          width: 100%;
          margin: 0;
          margin-top: 20px;
        }

        h3 {
          font-size: $fontsize * 1.5;
          margin: 0;
          padding-bottom: 5px;
          position: relative;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 15px;
            height: 15px;
            border-radius: 100px;
            width: 200px;
            background: #1d6341;
            opacity: 0.3;
          }
        }

        .member-title {
          margin: 0;
          padding-bottom: 10px;
          border-bottom: 2px solid lightgrey;
          font-size: $fontsize  * .80;
          color: $darkblue;
          text-transform: uppercase;
          letter-spacing: 1px;

          @include respond-to(phone) {
            padding-bottom: 3px;
            margin-bottom: 10px;
          }
        }

        p {
          font-size: $fontsize;
        }

        .member-desc {
          font-size: $fontsize * .9;
          white-space: pre-wrap;
          font-weight: lighter;
          // max-height: 200px;
          // overflow: auto;

          &:focus {
            overflow: visible;
          }

          @include respond-to(phone) {
            margin: 0;
          }
        }
      }
    }
  }
}

